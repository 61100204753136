import React from "react";
import Header from "../header/Header";
import Home from "../../pages/home";

const Main = ({ children }) => {
  return (
    <div className="container-scroller">
      <Header />
      <div className="container-fluid page-body-wrapper">
        {children}
      </div>
    </div>
  );
};

export default Main;
