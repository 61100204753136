import React from "react";
import styled from "styled-components";
import PhotoGallery from "../../Components/PhotoGallery/Index";


const Main = styled.main`
  max-width: 800px;
  width: 100%;
  margin: 80px auto 0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;

const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const ContactItem = styled.p`
  font-size: 18px;
  margin: 8px 0; /* Add extra margin between lines */
`;


const GoogleMap = () => {
  return (
    <iframe
      title="Google Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3361.4830974082342!2d-82.33217492416374!3d32.593306793351495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f0a5277a284751%3A0x95fcbdd646b01437!2sMoreSpaceUp!5e0!3m2!1sen!2sus!4v1690710269395!5m2!1sen!2sus"
      width="600"
      height="650"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

const Home = () => {
  return (
    <Main>
      <Section>
        <SectionTitle>Welcome to MoreSpaceUp</SectionTitle>
        <Paragraph>
          At MoreSpaceUp, we take pride in delivering self-storage solutions to
          cater to your diverse organizational requirements. Our
          state-of-the-art storage facilities are meticulously designed to meet
          the demands of both your personal and professional life. Whether
          you're looking to declutter your living space, safeguard precious
          possessions, or streamline your office, we have the perfect storage
          answer for you.
        </Paragraph>
        <Paragraph>
          With MoreSpaceUp, you gain access to a new dimension of storage
          excellence, where convenience, security, and accessibility converge
          seamlessly. Our facilities are equipped with state-of-the-art security
          cameras that operate 24/7, ensuring the safety and protection of your
          stored belongings. What's more, our user-friendly online platform
          allows you to effortlessly reserve your storage unit with just a few
          clicks, ensuring a contactless and hassle-free experience. It's our
          commitment to making your storage journey as seamless and secure as
          possible.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>Google Map</SectionTitle>
        <GoogleMap />
      </Section>
      <Section>
        <SectionTitle>Photo Gallery</SectionTitle>
        <PhotoGallery />
      </Section>
      {/* Add other sections as needed */}

      {/* Contact Details */}
      <Section>
        <SectionTitle>Contact</SectionTitle>
        <ContactSection>
          <ContactItem>Call Now: (478) 419-3690</ContactItem>
          <ContactItem></ContactItem> {/* Extra blank line */}
          <ContactItem>
            Address: 312 South Coleman Street, Swainsboro, GA 30401, USA
          </ContactItem>
          <ContactItem></ContactItem> {/* Extra blank line */}
          <ContactItem>Business Hours:</ContactItem>
          <ContactItem></ContactItem> {/* Extra blank line */}
          <ContactItem>Mon: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Tue: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Wed: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Thu: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Fri: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Sat: 9:00 AM – 6:00 PM</ContactItem>
          <ContactItem>Sun: 9:00 AM – 6:00 PM</ContactItem>
        </ContactSection>
      </Section>
    </Main>
  );
};

export default Home;
