import React, { useState } from 'react';
import styled from 'styled-components';



const FormContainer = styled.div`
  font-family: Arial, sans-serif;
  overflow-y: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const FormTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
`;

const FormInput = styled.input`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
`;

const FormButton = styled.button`
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const AgreementContainer = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  padding: 0 20px;
`;

const FixedButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
`;

const ThanksModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ThanksContent = styled.div`
  text-align: center;
`;

const CustForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [drivingLicense, setDrivingLicense] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [Email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [Printfullname, setPrintfullname] = useState(false);
  



  const handleSubmit = () => {
    if (
      !firstName ||
      !lastName ||
      !address ||
      !city ||
      !state ||
      !zipcode ||
      !drivingLicense ||
      !mobileNo ||
      !Email||
      !Printfullname
    ) {
      alert('Please fill out all required fields.');
      return;
    }
   

    const apiUrl = 'https://6dz6x6gnn4uvg323f3u5vwcqnq0yovxh.lambda-url.us-east-1.on.aws';

    const data = {
      firstName,
      lastName,
      address,
      city,
      state,
      zipcode,
      drivingLicense,
      mobileNo,
      Email,
      Printfullname,
    };

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
   .then((response) => response.json())
      .then((result) => {
        setResponseMessage(result.body);
        //setSubmitted(true); to get immediate response based on Vishal feedback
      })
      .catch((error) => console.error('API error:', error));
      setSubmitted(true);
  };


  const handleCloseModal = () => {
    setSubmitted(false);
  };

  const handleStripeButtonClick = () => {
    // Additional logic for handling Stripe button click
    // You may need to add logic here to close the modal
    // if necessary based on Stripe's API
  };


  if (submitted) {
    return (
      <ThanksModal>
        <CloseButton onClick={handleCloseModal}>Close</CloseButton>
        <ThanksContent>
          <h2>Thanks for your Application, MoreSpaceUp will email you shortly!</h2>
          <h2>Kindly proceed with the online payment!</h2>
          


          {/* Add Stripe-related HTML here */}
          
 <script
            async
            src="https://js.stripe.com/v3/buy-button.js"
            onLoad={handleStripeButtonClick} // Add this event listener
          ></script>
          <stripe-buy-button
  buy-button-id="buy_btn_1P8w7EEXnTy4dI5cWXWQkqTL"
  publishable-key="pk_live_51OaS9cEXnTy4dI5ckIqB0kuXNlpQrUrymAeXE1L5Gls1rujI7knv1ReE2wehhqrsrWPLLFTVeKXzAasuVMME4qdb00GaKYc8zZ"
>
</stripe-buy-button>
       </ThanksContent>
      </ThanksModal>
    );
  }

  return (
    <FormContainer>
      <FormWrapper>
        <form>
          <FormTitle>Customer Form</FormTitle>
          <FormLabel>First Name:</FormLabel>
          <FormInput
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <FormLabel>Last Name:</FormLabel>
          <FormInput
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <FormLabel>Address:</FormLabel>
          <FormInput
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <FormLabel>City:</FormLabel>
          <FormInput
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
          <FormLabel>State:</FormLabel>
          <FormInput
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <FormLabel>Zip Code:</FormLabel>
          <FormInput
            type="text"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
          <FormLabel>Driving License No:</FormLabel>
          <FormInput
            type="text"
            value={drivingLicense}
            onChange={(e) => setDrivingLicense(e.target.value)}
          />
          <FormLabel>Mobile No:</FormLabel>
          <FormInput
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
          <FormLabel>Email:</FormLabel>
          <FormInput
            type="text"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />

          

          <AgreementContainer>
            <h2>MoreSpaceUp Standard Agreement</h2>
            <p>
              I, {firstName},{lastName}  residing at {address}, agree to the terms and
              conditions of the MoreSpaceUp self storage service as follows:
            </p>
            <ul>
              <li>Cell:{mobileNo} </li>
              <li>E-mail: {Email}</li>
              <li>Customer Driver's License # :{drivingLicense}</li>
              <li>By electing to provide its e-mail address, Occupant agrees that notice by Owner may be given to Occupant via e-mail.</li>
              <li>The self storage facility is not responsible for any loss or damage to stored items.</li>
              <li>I will maintain the monthly rental payments and provide a 30-day notice before vacating the unit.</li>
              <li>Any changes to contact information or address must be promptly updated.</li>
              <li>This agreement is subject to the laws of the state of Georgia.</li>
            </ul>
            <p>Date: {new Date().toLocaleDateString()}</p>

            <p>    1. RENT: As used in this agreement, will be that part of the self-service storage facility described above. The Occupant agrees 
to pay the Owner for the use of the space and improvement thereon, the monthly sum listed above as the Monthly Rate. Monthly 
installments are payable in advance at the office of the self storage facility on or before the rental due date and a like amount for each 
month thereafter, until the termination of this agreement. Owner acknowledges receipt of the sum set out above showing payment 
through the date shown above. If any monthly installment is not paid by 5 days from the due date, Occupant shall be deemed to be in default. 
Default can also be the Occupant's failure to perform any terms or conditions of this Rental Agreement or Occupant's breach of the peace. 
In the event of Occupant's default, Owner may, without notice, deny the Occupant access to the property located in the self storage facility. 
Owner may place a different padlock on the rented premises over which only the Owner and his agents have control.
 Occupant agrees and understands that partial payments made to cure a default for non-payment of rent will not delay or stop the foreclosure and sale of Occupant's property. 
 Partial payments do not waive or avoid the legal effect of prior notices given to Occupant. Only full payment on Occupant's account prior to the published auction date will stop the 
scheduled sale of the property. Customers account must be in good standing to receive any promotions or discounts. </p>

  <p> 2. DENIAL OF ACCESS: If rent is not paid within 10 days of the monthly due date, Owner may without notice deny the Occupant 
access to the property located in the self storage facility. Access will be denied to any party other than the tenant unless said party 
retains gate code and key to lock on Space or has supplied Owner with written authorization from the tenant to enter the Space. 
Occupant's access to the facility may also be conditioned in any manner deemed reasonably necessary by Owner to maintain order on 
the premises. Such measures may include, but are not limited to, restricting hours of operation, requiring verification of occupant's 
identity and inspecting vehicles that enter the premises. Additionally, if Occupant is renting more than one Space at any given time, 
default on one rented Space shall constitute default on all rented Spaces, entitling Owner to deny access to Occupant to all rented 
Spaces. </p>
  
 <p> 3. FEES: Concurrently with the execution of this Rental Agreement, Occupant shall pay to Owner a nonrefundable NEW ACCOUNT 
ADMINISTRATION FEE in the amount as set forth above. A bill for the monthly rent will not be sent unless requested. However, 
Occupant may elect to pay an additional fee as an INVOICE FEE, set forth by Owner. Occupant acknowledges that late payment of
monthly rent will cause Owner to incur costs not contemplated by this lease, the exact amount of such costs being extremely difficult to 
fix. Therefore, if any monthly rent is received after 5 DAYS from the rental due date, Occupant shall pay to Owner a FIRST 
LATE FEE for each month rent is past due. Furthermore, if any monthly rent payment is received after 5 DAYS from the rental 
due date, a SECOND LATE FEE will be assessed for each month rent is past due. Late fees are assessed at the full and sole 
discretion of the Owner. Said late charges are due and payable without demand from Owner. The parties agree that these late charges 
represent a fair and reasonable estimate of the costs the Owner will incur by reason of late payment by Occupant. If any check is 
dishonored for any reason, said late charges shall be due and payable in addition to a return check charge identified above as an NSF 
FEE. If Occupant's property is processed for sale at public auction, Occupant shall be responsible for a minimum public auction 
processing fee shown above as AUCTION FEE. If Occupant's lock must be cut, Occupant shall be responsible for LOCK CUT FEE 
identified above. </p>
  
 <p> 4. USE AND COMPLIANCE WITH LAW: (a) The space named herein is to be used by the Occupant solely for the purpose of storing 
any personal property belonging to the Occupant. The Occupant agrees not to store any explosives, or any flammable, odorous, 
noxious, corrosive, hazardous or pollutant materials or any other goods in the space that would cause danger or nuisance to the space 
or facility. Occupant shall not store any improperly packaged food or perishable goods, or other items that may attract rodents, vermin, 
or other infestation in the Space. The Occupant agrees that the property will not be used for any purposes unlawful or contrary to any 
ordinance, regulation, fire code or health code and the Occupant agrees not to commit waste, nor to create a nuisance, nor alter, nor 
affix signs on the space, and will keep the space in good condition during the term of the Agreement. The occupant agrees not to store 
jewels, furs, heirlooms, art works, collectibles or other irreplaceable items having special or emotional value to the occupant. Occupant 
waives any claim for emotional or sentimental attachment to Occupant's property. There shall be NO HABITABLE OCCUPANCY of the 
space by humans or pets of any kind for any period whatsoever and violation of these prohibitions shall be grounds for immediate 
TERMINATION of the Agreement. If hazardous substances are stored, used, generated or disposed of on or in the premises or if the 
premises become contaminated in any manner for which the Occupant is legally liable, Occupant shall indemnify and hold harmless the 
Owner from any and all claims, damages, fees, judgments, penalties, costs, liabilities or losses, and any and all sums paid for 
settlement of claims, attorneys' fees, consultant and expert fees, arising during or after the lease term and arising as a result of that 
contamination by Occupant. (b) Occupant agrees not to conduct any business out of space, and further agrees that the space is not to 
be used for any type of work shop, for any type of repairs or for any sales, renovations, decoration, painting, or other contracting in the 
space. Use of any utilities on premises is strictly prohibited except by express written agreement and arrangement with Owner and for 
an additional Utility charge set forth by Owner. Unless given written permission by owner, violation of these prohibitions shall be 
deemed a default and shall be grounds for immediate Termination of this agreement and shall cancel Occupant's right of Occupancy. 
Occupant agrees to hold Owner, other Occupants and third parties harmless and indemnify, safe and defend such persons from any 
loss resulting from the violation of this provision.  </p>
  
 <p> 5. CONDITION AND ALTERATION OF PREMISES: Occupant assumes responsibility for having examined the premises and hereby 
accepts it AS IS, being in good order and condition and agrees to pay Owner promptly for any repairs to the space resulting from 
negligence or misuse by the Occupant, Occupant's invitees, licensees and guests. Occupant understands that all unit sizes are 
approximate and enters into this Rental Agreement without reliance on the estimated size of the Space. Occupant shall make no
alterations or improvements to the space without prior written consent of Owner. Should Occupant damage or depreciate the Space, or 
make alterations or improvements without the prior consent of the Owner, or require the Owner to incur costs to clean the Space upon 
termination, then all costs necessary to restore the Space to its prior condition shall be borne by Occupant. Owner has the right to 
declare any such costs to repair as "rent" and non-payment of said costs entitles Owner to deny Occupant access to the Space. </p>
  
 <p> 6. LIMITATION OF VALUE: Occupant agrees that in no event shall the total value of all property stored be deemed to exceed 
$1500.00 unless Owner has given permission in writing for Occupant to store property exceeding that value. Occupant agrees that the
maximum value for any claim or suit by Occupant, including but not limited to any suit which alleges wrongful or improper foreclosure or 
sale of the contents of a storage unit is the total value referenced above. Nothing in this section shall be deemed to create any liability 
on the part of Owner to Occupant for any lost or damage to Occupant's property, regardless of cause. </p>
  
 <p> 7. ABANDONMENT: This Agreement shall automatically terminate if Occupant abandons the Space. Occupant shall have abandoned 
the Space if Occupant has removed the locking device from the Space and IS NOT current in all obligations hereunder or lock is cut 
after default and it is determined, in the Owner's discretion, that the items in the unit have little or no value. Any property left in the 
Space may be disposed of by the Owner. Rent paid for month in which Occupant moves out early shall not be refunded. THERE ARE
NO RENT REFUNDS. </p>
  
 <p> 8. TERMINATION: This agreement shall continue from month to month unless Occupant or Owner delivers to the other party a five-day 
advanced written notice of its intentions to terminate the Agreement. Upon termination of this Agreement, Occupant shall remove all 
personal property from the space and shall deliver possession of the space to Owner unless such property is subject to Owner's lien 
rights as referenced in this Rental Agreement. If Occupant fails to fully remove its property from the space within the time required, 
Occupant shall be an Occupant at sufferance and Owner, at its option, may, without further notice or demand, either directly or through 
legal process, reenter the Occupant's unit and remove all property therefrom without being deemed guilty in any manner of trespassing 
or conversion. No refunds are allowed for partial- month occupancies. Occupant shall be charged a CLEAN UP FEE, identified above, if 
Owner is required to remove any debris from either inside or outside the rented Space after Occupant vacates the Space.  </p>
  
 <p> 9. OCCUPANT'S RISK OF LOSS: THE OWNER IS NOT A WAREHOUSEMAN ENGAGED IN THE BUSINESS OF STORING 
GOODS FOR HIRE, AND NO BAILMENT IS CREATED BY THIS AGREEMENT. THE OWNER EXERCISES NEITHER CARE, 
CUSTODY, NOR CONTROL OVER THE TENANT'S STORED PROPERTY. All property stored within or on the space by Occupant or 
located at the facility by anyone shall be stored at Occupant's sole risk. Occupant must take whatever steps he deems necessary to 
safeguard what is at the facility or in or on the space. Occupant shall assume full responsibility for who has the keys and access to the 
space. Owner and Owner's employees and agents shall not be liable for any loss of or damage to any personal property while at the 
rented premises arising from any cause whatsoever, including, but not limited to, theft, mysterious disappearance, vandalism, fire, 
smoke, water, mold, mildew, hurricanes, rain, tornados, explosions, terrorist acts, rodents, insects, Acts of God, the active or passive 
acts or omissions or negligence of the Owner, Owner's agents or employees. IT IS AGREED BY OCCUPANT THAT THIS RELEASE 
OF OWNER'S LIABILITY IS A BARGAINED FOR CONDITION OF THE RENT SET FORTH HERE AND THAT WERE OWNER NOT 
RELEASED FROM LIABILITY, A MUCH HIGHER RENT WOULD HAVE TO BE AGREED UPON. Owner does not promise safety or 
security of persons or property on the premises, and Owner had no duty of safety or security of same under any circumstances. Video 
cameras may be non-operational or unmonitored. Access control devices may be unmonitored and may occasionally malfunction.  </p>
 <p> 10. PERSONAL INJURY: Owner and Owner's agents and employees shall not be liable whatsoever to any extent to Occupant or 
Occupant's invitees, family, employees, agents or servants for any personal injury or death arising from Occupant's use of the storage 
space or premises from any cause whatsoever including, but not limited to, the active or passive acts or omissions or negligence of the 
Owner, Owner's agents or employees. </p>
 <p> 11. INDEMNIFICATION OF OWNER: Occupant will indemnify and hold the Owner harmless from and against any and all manner of 
claims for damages or lost property or personal injury and costs including attorney's fees arising from Occupant's lease of the space on 
the facility or from any activity, work or thing done, permitted or suffered by Occupant in or on the space or about the facility. In the 
event that the space is damaged or destroyed by fire or other casualty, Owner shall have the right to remove the contents of the space 
and store it at the Occupant's sole cost and expense without liability for any loss or damage whatsoever, and Occupant shall indemnify 
and hold Owner harmless from and against any loss, cost, or expense of Owner in connection with such removal and storage. Should 
any of Owner's employees perform any services for Occupant at Occupant's request, such employee shall be deemed to be the agent 
of the Occupant regardless of whether payment for such services is made or not, and Occupant agrees to indemnify and hold Owner 
harmless from any liability in connection with or arising from directly or indirectly such services performed by employees of Owner. 
Notwithstanding that Owner shall not be liable for such occurrences, Occupant agrees to notify Owner immediately upon the 
occurrence of any injury, damage, or loss suffered by Occupant or other person in any of such circumstances.  </p>
 <p> 12. OWNER'S RIGHT TO ENTER: In cases where Owner considers it necessary to enter the space for purposes of examining the 
space for violation of this agreement or condition in the space or making repairs or alterations thereto, or to comply with this agreement, 
Occupant agrees that Owner, or Owner's representative, shall have the right without notice to enter into and upon the space and Owner 
reserves the right to remove contents to another space.  </p>
 <p> 13. OWNER'S LIEN RIGHTS :
OWNER HAS A STATUTORY LIEN, THAT IS A CLAIM OR SECURITY INTEREST ON ALL PERSONAL PROPERTY STORED IN 
OCCUPANT'S SPACE FOR RENT, LABOR, OR OTHER CHARGES, PRESENT OR FUTURE, IN RELATION TO THE PERSONAL 
PROPERTY AND FOR ITS PRESERVATION OR EXPENSES REASONABLY INCURRED IN ITS SALE OR OTHER DISPOSITION 
PURSUANT TO THIS AGREEMENT. PERSONAL PROPERTY STORED IN OCCUPANT'S SPACE WILL BE SOLD OR 
OTHERWISE DISPOSED OF IF NO PAYMENT HAS BEEN RECEIVED FOR A CONTINUOUS THIRTY-DAY PERIOD AFTER 
DEFAULT. IN ADDITION, UPON OCCUPANT'S DEFAULT, OWNER MAY, WITHOUT NOTICE, DENY OCCUPANT ACCESS TO 
THE PERSONAL PROPERTY STORED IN OCCUPANT'S SPACE UNTIL SUCH TIME AS PAYMENT IS RECEIVED. IF ANY 
MONTHLY INSTALLMENT IS NOT MADE BY THE SEVENTH CALENDAR DAY OF THE MONTH DUE, OR IF ANY CHECK GIVEN 
IN PAYMENT IS DISHONORED BY THE FINANCIAL INSTITUTION ON WHICH IT IS DRAWN, THE OCCUPANT IS IN DEFAULT 
FROM DATE PAYMENT WAS DUE.
(a) For purposes of Owner's lien: "personal property " means movable property, not affixed to land, and includes, but is not limited to, 
goods, wares, merchandise, motor vehicles, trailers, watercraft, household items, and furnishings. "Last known address" means the 
street address or post office box address provided by Occupant in the latest Rental Agreement or the address provided by Occupant in 
a subsequent written notice of a change of address by hand delivery, verified mail, or e-mail. (b) Owner's lien is superior to any other 
lien or security interest, except those which are evidenced by a certificate of title or perfected and recorded prior to the date of this 
Rental Agreement in Georgia, in the name of Occupant, either in the county of Occupant's "last known address" or in the county where 
the self-service storage facility is located, except any tax lien as provided by law and except those liens or security interests of whom 
Owner has knowledge through Occupant's disclosure in this Rental Agreement or through other written notice. (c) Occupant attests that 
the personal property in Occupant's space(s) is free and clear of all liens and secured interests except for those liens and secured 
interests Occupant described herein above. Owner's lien attaches as of the date the personal property is brought to the self-service 
storage facility. Except as otherwise specifically provided in this Rental Agreement, the exclusive care, custody, and control of any and 
all personal property stored in the leased space shall remain vested in Occupant. Owner does not become a bailee of Occupant's 
personal property by the enforcement of Owner's lien. (d) If Occupant has been in default continuously for thirty (30) days, Owner may 
enforce its lien, provided Owner shall comply with the following procedure:
(1) Occupant shall be notified of Owner's intent to enforce Owner's lien by written notice delivered in person, by verified mail or by email. Owner also shall notify other parties with superior liens or security interests as defined in this Rental Agreement. A notice given 
pursuant to this Rental Agreement shall be presumed sent when it is deposited with the United States Postal Service or the statutory 
overnight delivery service properly addressed with postage or delivery fees prepaid or sent by e-mail. If Owner sends notice of a 
pending sale of property to Occupant's last known e-mail address and does not receive a nonautomated response or a receipt of 
delivery to the e-mail address, Owner shall send notice of the sale to Occupant by verified mail to Occupant's last known address or to 
the last known address of the designated agent of the Occupant before proceeding with the sale. (a) Owner's notice to Occupant shall 
include an itemized statement of Owner's claim showing the sum due at the time of the notice and the date when the sum became due. 
(b) Owner's notice shall notify Occupant of denial of access to the personal property and provide the name, street address, e-mail 
address, and telephone number of Owner or its designated agent, whom Occupant may contact to respond to this notice. (c) Owner's 
notice shall demand payment within a specified time, not less than fourteen (14) days after delivery of the notice. (d) It shall state that, 
unless the claim is paid, within the time stated in the notice, the personal property will be advertised for public sale to the highest bidder, 
and will be sold at a public sale to the highest bidder, at a specified time and place.
(2) After the expiration of the time given in Owner's notice, Owner shall publish an advertisement of the public sale to the highest 
bidder, once a week, for two consecutive weeks, in the legal organ for the county where the self-service storage facility is located. The 
sale shall be deemed commercially reasonable if at least three (3) independent bidders attend the sale at the time and place 
advertised. "Independent bidder" means a bidder who is not related to and who has no controlling interest in, or common pecuniary 
interest with, Owner or any other bidder. (a) The advertisement shall include: a brief and general description of the personal property, 
reasonably adequate to permit its identification; the address of the self-storage facility, and the number, if any, of the space where the 
personal property is located, and the name of Occupant; and the time, place, and manner of the public sale. (b) The public sale to the 
highest bidder shall take place not sooner than fifteen (15) days after the first publication. (c) Regardless of whether a sale involves the 
property of more than one Occupant, a single advertisement may be used to advertise the disposal of property at the sale. A public sale 
includes offering the property on a publicly accessible website that regularly conducts online auctions of personal property. Such sale 
shall be considered incidental to the self-storage business and no license shall be required.(3) If no one purchases the property at the 
public sale and if Owner has complied with the foregoing procedures, Owner may otherwise dispose of the property and shall notify 
Occupant of the action taken. Any sale or disposition of the personal property shall be held at the self-storage facility or at the nearest 
suitable place to where the personal property is held or stored.
(4) Before any sale or other disposition of personal property pursuant to this Agreement, Occupant may pay the amount necessary to 
satisfy the lien and the reasonable expenses incurred, and thereby redeem the personal property and thereafter Owner shall have no 
liability to any person with respect to such personal property.
(5) A Purchaser in good faith of the personal property sold to satisfy Owner's lien takes the property free of any rights of persons 
against whom the lien was valid, despite noncompliance by Owner with the requirements of this Agreement. (6) In the event of a sale, 
Owner may satisfy his or her lien from the proceeds of the sale. Owner shall hold the balance of the proceeds, if any, for Occupant or 
any notified secured interest holder. If not claimed within two years of the date of sale, the balance of the proceeds shall be disposed of 
in accordance with Article 5 of Chapter 12 of Title 44, the "Disposition of Unclaimed Property Act" and as it may be amended. In no 
event shall Owner's liability exceed the proceeds of the sale. If the Rental Agreement contains a limit on the value of property stored in 
Occupant's storage space, the limit shall be deemed to be the maximum value of the property stored in that space. If the property upon 
which the lien is claimed is a motor vehicle, trailer, or watercraft and rent and other charges related to the property remain unpaid or 
unsatisfied for 60 days following the maturity of the obligation to pay rent, Owner may have the property towed in lieu of foreclosing on 
the lien. If a motor vehicle, trailer, or watercraft is towed as authorized in this section, Owner shall not be liable for the motor vehicle, 
trailer, or watercraft or any damages to the motor vehicle, trailer, or watercraft once the tower takes possession of the property.  </p>

<p> 14. SECURITY AGREEMENT: This Agreement shall constitute a security agreement covering the contents (hereinafter referred to as 
"collateral") of the Space, and a security interest shall attach thereto for the benefit of, and is hereby granted to Owner by Occupant to 
secure the payment and performance of Occupant's default hereunder. Owner, in addition to all other rights and remedies it may have 
in such event, may exercise any right or remedy wish respect so the Collateral which it may have under the Uniform Commercial Code 
or otherwise. It is expressly understood that Owner retains its Owner's statutory lien. All rights of Owner hereunder or in law or in equity 
are cumulative, and an exercise of one or more of such rights shall not constitute a waiver of any other rights. Occupant hereby waives 
and renounces its right to the benefit of the exemptions provided under O.C.G.A. 44-13-I et seq. and as it may be amended.  </p>
  
 <p> 15. OCCUPANT'S LIABILITY: In the event of a foreclosure, it is understood and agreed that the liability of Occupant for the rents, 
charges, costs, and expenses provided for in this rental agreement shall not be relinquished, diminished or extinguished prior to 
payment in full. It is further agreed that Occupant shall be personally liable for all rents, charges, costs and expenses, including those 
incurred in the sale and/or disposition of the Occupant's property as provided for above. Owner may use a collection agency thereafter 
to secure any remaining balance owed by Occupant after the application of sale proceeds if any and Occupant shall be liable for all 
fees and costs for said collection. If any property remains unsold after foreclosure and sale, owner may dispose of said properly in any 
manner considered appropriate by Owner.  </p>
  
 <p> 16. ASSIGNMENT AND SUBLETTING: Occupant shall not assign this Agreement or sublet the whole or any portion of the space 
rented hereunder.  </p>
  
 <p> 17. WAIVER/ENFORCEABILITY: In the event any part of this Agreement shall be held invalid or unenforceable the remaining part of 
this Rental Agreement shall be in full force and effect as though any invalid or unenforceable part or parts were not written into this 
Agreement. No waiver by Owner of any provisions hereof shall be deemed a waiver of any other provision hereof or of any subsequent 
default or breach by Occupant of the same or any other provision.  </p>
  
 <p> 18. ATTORNEY'S FEES: In the event Owner obtains services of an attorney to recover any sums due under this agreement, for an 
unlawful detainer, for the breach of any Covenant or conditions of this Agreement, or in defense of any demand, claim, or action 
brought by Occupant, Occupant agrees to pay to Owner the reasonable costs, expenses, and attorney's fees incurred in such actions.  </p>
  
 <p>19. SUCCESSION: This Agreement is binding upon the parties, their heirs, successors, personal representatives and assigns.  </p>
  
 <p> 20. GOVERNING LAW: This Agreement and any actions between the parties shall be interpreted by and governed by the laws of the 
State of Georgia.  </p>
  
 <p> 21. WAIVER OF JURY TRIAL: Owner and Occupant waive their respective rights to trial by jury of any cause of action, claim, 
counterclaim, or cross complaint brought by either Owner against Occupant, or Occupant against Owner on any matter arising out of or 
in any way connected with this Rental Agreement, Occupant's use or occupancy of the storage space, or any claim of bodily injury or 
property damage or the enforcement of any remedy under any law, statute, or regulation. </p>
  
 <p> 22. LIMITED WARRANTY: This Agreement contains the entire Agreement of the parties and no representation or agreements, oral or 
otherwise, between the parties not embodied herein shall be of any force or effect. The agents and employees of Owner are not 
authorized to make warranties about the space, premises, and facility referred to in this Agreement. Owner's agents and employees' 
ORAL STATEMENTS DO NOT CONSTITUTE WARRANTIES, and shall not be relied upon by the Occupant nor shall any of said 
statements be considered a part of the Agreement. The entire Agreement and understanding of the parties hereto is to be considered a 
part of the Agreement. This entire Agreement and understanding of the parties hereto is embodied in this writing and NO OTHER 
WARRANTIES are given beyond those set forth in this Agreement. The parties hereto agree that the IMPLIED WARRANTIES OF 
MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE and all other warranties, express or implied, ARE EXCLUDED 
from this transaction and shall not apply to the leased space, premises, and facility referred to herein. It is further understood and 
agreed that Occupant has been given an opportunity to inspect, and has inspected this space, premises, and facility, and that Occupant 
accepts such leased space, premises, and facility AS IS and WITH ALL FAULTS.  </p>
  
 <p> 23. RULES: Owner shall have the right to establish or change the hours of operation for the Facility and to promulgate Rules and 
Regulations for the safety, care and cleanliness of the Space or the preservation of good order in the Facility. Occupant agrees to follow 
all Rules and Regulations now in effect, or that may be put into effect from time to time.  </p>
  
 <p> 24. Notices from Owner: All notices from Owner shall be sent by first class mail postage prepaid to Occupant's last known address or 
to the electronic mail address provided by the Occupant in this Rental Agreement. Notices shall be deemed given when deposited with 
the U. S. Postal Service or when sent by electronic mail. All statutory notices shall be sent as required by law. </p>
  
 <p> 25. Notices from Occupant: Occupant represents and warrants that the information Occupant has supplied in this Rental Agreement 
is true, accurate and correct and Occupant understands that Owner are relying on Occupant's representations. Occupant agrees to 
give prompt written notice to Owner of any change in Occupant's address, any change in the liens and secured interest on Occupant's 
property in the Space and any removal or addition of property to or out of the Space within ten (10) days of the change. Occupant 
understands he must personally deliver such notice to Owner or mail the notice by certified mail, return receipt requested, with postage 
prepaid to Owner at the Facility address set forth above or by e-mail only if e-mail is acknowledged by Owner.  </p>
  
 <p> 26. CHANGES: All terms of this Agreement, including but without limitation, monthly rental rate, conditions of occupancy and other 
charges, are subject to change upon thirty (30) days prior written notice to Occupant. If changed, the Occupant may terminate this 
Agreement on the effective date of the change by giving Owner ten (10) days prior written notice to terminate after receiving notice of 
the change, If the Occupant does not give such notice, the change shall become effective and apply to his occupancy. </p>
  
 <p> 27. OCCUPANT'S LOCK: Occupant shall provide at Occupant's own expense a lock for the space which Occupant, in Occupant's sole 
discretion, deems sufficient to secure the space. Space shall be immediately locked upon execution of the agreement. Occupant shall 
not provide Owner or Owner's agents with a key and/or combination to Occupant's lock unless deliveries are to be accepted by Owner 
on Occupant's behalf. If lock is not placed on unit or Space is found without a lock, Owner has the right to place a new lock on the 
Space to secure the Unit without creating a bailment.  </p>
  
 <p> 28. MILITARY SERVICE: In order to comply with SERVICE MEMBERS CIVIL RELIEF ACT it is Occupant's obligation to notify the 
Owner in writing with in 5 days that Occupant and any Occupant family member storing goods at the Facility are in active military service, in order to 
determine Occupant's qualifications under this Act. If Occupant's military status or Occupant's family member's military status changes, 
Occupant is required to notify the Owner in writing of this change immediately.  </p>
  
 <p> 29. FINANCIAL INFORMATION: The Owner explicitly does not provide any assurance or guarantee regarding the invulnerability of the Occupant's financial information. 
This encompasses a broad spectrum of data, such as credit card details and bank account information.
It is important to note that the Owner does not warrant against the possibility of theft or any compromise of this financial information by external parties.

By entering into this agreement, the Occupant willingly and knowingly waives and releases any claims or actions against the Owner, as well as its agents, employees, and affiliates. 
This waiver pertains specifically to damages that may arise from the unauthorized use or access of the aforementioned financial information by third parties. 
Essentially, the Occupant acknowledges and accepts the inherent risks associated with the transmission and storage of financial data, absolving the Owner and its associated entities
 of liability in the event of any such unfortunate incidents.. </p>

 <p>  31. PERMISSION TO CALL,  E-MAIL OR TEXT: Occupant recognizes Owner and Occupant are entering to a business 
relationship as Owner and Occupant. As such, to the extent any Federal or State law prohibits Owner from contacting Occupant by 
phone, e-mail or text, Occupant hereby consents to Owner phoning, faxing, e-mailing and texting Occupant with marketing and/or 
other business related communications. </p>

<p>  31. Dispute Resolution Terms: In the event of any disputes or disagreements arising in the context of this self-storage arrangement,
 it is hereby stipulated that the venue for the resolution of such matters shall be exclusively situated in the city of Lawrenceville, GA, Georgia, identified by the zip code 30046. 
Both parties mutually consent to subject themselves to the exclusive jurisdiction and adjudication of the courts within the aforementioned location, facilitating an effective and expeditious resolution process. 
This provision underscores the deliberate choice of Lawrenceville, Georgia, and its associated zip code 30046 as the designated locale for addressing and settling any potential disagreements, 
ensuring a fair and equitable legal framework for both the owner and the occupant involved in this self-storage agreement. </p>


 <p> 33. INSURANCE OBLIGATION: THE OWNER DOES NOT PROVIDE ANY TYPE OF INSURANCE WHICH WOULD PROTECT THE 
OCCUPANT'S PERSONAL PROPERTY FROM LOSS BY FIRE, THEFT, OR ANY OTHER TYPE OF CASUALTY LOSS. IT IS THE 
OCCUPANT'S RESPONSIBILITY TO OBTAIN SUCH INSURANCE. Occupant, at Occupant's sole expense, shall maintain an 
insurance policy of fire, extended coverage endorsement, burglary, vandalism and malicious mischief insurance for the actual cash 
value of the stored property. Insurance on Occupant's stored property is a material condition of this agreement, and is for the benefit of 
both Occupant and Owner. Occupant’s failure to carry the required insurance is a breach of this agreement, and Occupant 
assumes all risk of loss to stored property that would be covered by such insurance, including any loss due to any acts whatsoever of 
Owner, Owner’s agents or employees, including, but not limited to the alleged negligent or intentional acts of Owner, or Owner’s agents 
or employees, including negligent or intentional disposal of Occupant's stored property. Occupant expressly agrees that the carrier of 
such insurance shall not be subrogated to any claim of Occupant against Owner, Owner's agents or employees. It is expressly agreed 
between Occupant and Owner that it is intended that insurance coverage be acquired by Occupant to cover loss of the property due to 
any acts whatsoever of Owner, Owner's agents, or employees, whether intentional or negligent, or active or passive in nature, which 
results in any loss, disposal, or damage to Occupant's stored property </p>

<p>  NOTICE TO OCCUPANT: DO NOT SIGN THIS AGREEMENT BEFORE YOU READ ALL PAGES (This is a multi-page document), 
AND FULLY UNDERSTAND THE CONTENTS CONTAINED HEREIN. KEEP A COPY OF THIS AGREEMENT TO PROTECT YOUR 
LEGAL RIGHTS. OCCUPANT HEREBY ACKNOWLEDGES BY SIGNING THIS AGREEMENT THAT HE HAS READ, UNDERSTOOD 
AND ACCEPTS ALL THE TERMS AND CONDITIONS EXPRESSED IN THIS MULTI-PAGE AGREEMENT. </p>
           
            <p>
              Owner: Binoy Rappai, Date: {new Date().toLocaleDateString()}<br />
              Facility Manager<br />
              <br />
              Occupant: {firstName}, {lastName}, Date: {new Date().toLocaleDateString()}<br />
             
            </p>

                      <p> Printed Name Equivalence Clause: By printing my full name below, 
              I acknowledge and agree that my printed name is intended to have the same legal effect as my signature and demonstrates my acceptance and commitment to 
              abide by the terms and conditions outlined in this contract.</p>
          </AgreementContainer>

          <FormLabel>Print FullName:</FormLabel>
            <FormInput
            type="text"
            value={Printfullname}
            onChange={(e) => setPrintfullname(e.target.value)}
          />

          <FixedButtonWrapper>
            <FormButton type="button" onClick={handleSubmit}>
              Submit
            </FormButton>
          </FixedButtonWrapper>
        </form>
      </FormWrapper>
    </FormContainer>
  );
};

export default CustForm;
